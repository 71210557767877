import React, { useState } from "react";
import "./CreateCourseForm.css";
import AddDoc from "../AddDoc/AddDoc";
import CloseIcon from '@mui/icons-material/Close';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

function CreateCourseForm() {
  const [title, setTitle] = useState(null);
  const [header, setHeader] = useState(null);
  const [text, setText] = useState(null);
  const [type, setType] = useState("free");
  const [pointsReq, setPointsReq] = useState(0);
  const [imageFile, setImageFile] = useState()

  const [toggleCreateModule, setToggleCreateModule] = useState(false)


  return (

    <>
    {/* <button className="add_doc_btn" onClick={() => {
      if (toggleCreateModule) {
        setToggleCreateModule(false)
      }else{
        setToggleCreateModule(true)
      }
    }}><CreateNewFolderIcon /></button> */}

      <div className={`create_course_form`}>

        <h1>Create Module</h1>

        <div className="row">
          <label htmlFor="field0">Icon</label>
          <input
            required
            name="field0"
            type="file"
            accept="image/png"
            onChange={(e) => setImageFile(e.target.files[0])}
          />
        </div>

        <div className="row">
          <label htmlFor="field1">title</label>
          <input
            required
            name="field1"
            type="text"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="row">
          <label htmlFor="field2">Sub Header</label>
          <input
            required
            name="field2"
            type="text"
            onChange={(e) => setHeader(e.target.value)}
          />
        </div>

        <div className="row">
          <label htmlFor="field3">Module Description</label>
          <textarea
            required
            name="field3"
            type="text"
            onChange={(e) => setText(e.target.value)}
          />
        </div>

        <div className="col">
          <AddDoc title={title} header={header} text={text} type={type} pointsReq={pointsReq} imageFile={imageFile}/>
        </div>

      </div>

    </>
  );
}

export default CreateCourseForm;
